import './App.css';
import {lazy, Suspense, useEffect, useState} from "react";
import api from "./Api";
import {AuthContext} from "./AuthContext";

function App() {

  const AppLayout = lazy(() => import('./AppLayout'));
  const [ctx, setCtx] = useState({});

  useEffect(()=> {
    api.get('user-account')
      .then(res => {
        const tenantId = res.data.tenantId;
        const tenants = res.data.tenants;
        const user = res.data.user;
        const role = tenants.find(t=> t.tenantId===tenantId)?.role;
        const isReadOnly = role !== 'OWNER'
            && role !== 'ADMIN' && role !== 'STANDARD';
        setCtx({tenantId, tenants, user, isReadOnly});
      })
      .catch(err => console.log(err.message));
  }, []);

  return (
      ctx ?
          <Suspense >
            <AuthContext.Provider value={ctx}>
              <AppLayout />
            </AuthContext.Provider>
          </Suspense>
          : <div>Loading...</div>
  );
}

export default App;
